.tab {
  min-height: 500px;
}

.card {
  min-height: 600px;
}

.downloadButtons > button {
  margin: 10px;
}
