.header {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.logo {
  width: 50px;
  float: left;
  margin: 7px 30px 0 -15px;
}

[data-theme="dark"] .siteLayoutBackground {
  background: #141414;
}
